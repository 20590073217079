@font-face {
  font-family: "foo-icons";
  src: url("./fonts/foo-icons.eot?dnbuln");
  src: url("./fonts/foo-icons.eot?dnbuln#iefix") format("embedded-opentype"),
    url("./fonts/foo-icons.ttf?dnbuln") format("truetype"),
    url("./fonts/foo-icons.woff?dnbuln") format("woff"),
    url("./fonts/foo-icons.svg?dnbuln#foo-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Brands", "foo-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fo-chile-poblano:before {
  content: "\61";
}
.fo-chocolate:before {
  content: "\62";
}
.fo-cinnamon:before {
  content: "\63";
}
.fo-corn-cob:before {
  content: "\64";
}
.fo-nutella:before {
  content: "\66";
}
.fo-onion:before {
  content: "\67";
}
.fo-pan-integral:before {
  content: "\68";
}
.fo-parsley:before {
  content: "\69";
}
.fo-rice:before {
  content: "\6a";
}
.fo-sausage:before {
  content: "\6b";
}
.fo-soy-meat:before {
  content: "\6c";
}
.fo-spanish-chorizo:before {
  content: "\6d";
}
.fo-almond:before {
  content: "\65";
}
.fo-artichokes:before {
  content: "\6e";
}
.fo-bacon:before {
  content: "\6f";
}
.fo-banana:before {
  content: "\70";
}
.fo-beef:before {
  content: "\71";
}
.fo-beef-tongue:before {
  content: "\72";
}
.fo-black-sesame-seeds:before {
  content: "\73";
}
.fo-caramel:before {
  content: "\74";
}
.fo-chicken:before {
  content: "\75";
}
.fo-corn-tortilla:before {
  content: "\76";
}
.fo-cruotones:before {
  content: "\77";
}
.fo-cucumber-cut-in-half:before {
  content: "\78";
}
.fo-eel:before {
  content: "\79";
}
.fo-ketchup:before {
  content: "\7a";
}
.fo-lentils:before {
  content: "\41";
}
.fo-marlin:before {
  content: "\42";
}
.fo-milk:before {
  content: "\43";
}
.fo-pasta:before {
  content: "\44";
}
.fo-pastor:before {
  content: "\45";
}
.fo-peanuts:before {
  content: "\46";
}
.fo-pepperoni:before {
  content: "\47";
}
.fo-pickle:before {
  content: "\48";
}
.fo-pork-leg:before {
  content: "\49";
}
.fo-potato:before {
  content: "\4a";
}
.fo-pumpkin-pip:before {
  content: "\4b";
}
.fo-raspberry:before {
  content: "\4c";
}
.fo-squid:before {
  content: "\4e";
}
.fo-surimi:before {
  content: "\4f";
}
.fo-tripas:before {
  content: "\50";
}
.fo-turkey-ham:before {
  content: "\51";
}
.fo-vanilla:before {
  content: "\52";
}
.fo-vino-tinto:before {
  content: "\53";
}
.fo-cashews:before {
  content: "\54";
}
.fo-asparagus:before {
  content: "\7b";
}
.fo-beans:before {
  content: "\7c";
}
.fo-beetroot:before {
  content: "\7d";
}
.fo-bell-pepper:before {
  content: "\7e";
}
.fo-cabbage:before {
  content: "\7f";
}
.fo-carrot:before {
  content: "\80";
}
.fo-cebollin:before {
  content: "\81";
}
.fo-celery:before {
  content: "\82";
}
.fo-cucumber:before {
  content: "\83";
}
.fo-fries:before {
  content: "\84";
}
.fo-lettuce:before {
  content: "\85";
}
.fo-mushrooms:before {
  content: "\86";
}
.fo-onion2:before {
  content: "\87";
}
.fo-onion-rings:before {
  content: "\88";
}
.fo-pea:before {
  content: "\89";
}
.fo-pickles:before {
  content: "\8a";
}
.fo-portobello:before {
  content: "\8b";
}
.fo-potatoes:before {
  content: "\8c";
}
.fo-radish:before {
  content: "\8d";
}
.fo-spinach:before {
  content: "\8e";
}
.fo-tomato:before {
  content: "\8f";
}
.fo-clam:before {
  content: "\90";
}
.fo-crab:before {
  content: "\91";
}
.fo-octopus:before {
  content: "\92";
}
.fo-oyster:before {
  content: "\93";
}
.fo-shrimp:before {
  content: "\94";
}
.fo-bacon2:before {
  content: "\4d";
}
.fo-chorizo:before {
  content: "\55";
}
.fo-pepperoni2:before {
  content: "\56";
}
.fo-sausage2:before {
  content: "\57";
}
.fo-green-sauce:before {
  content: "\58";
}
.fo-habanero-sauce:before {
  content: "\59";
}
.fo-hot-jalapeno:before {
  content: "\5a";
}
.fo-jalapeno:before {
  content: "\5b";
}
.fo-red-sauce:before {
  content: "\5c";
}
.fo-apple:before {
  content: "\5d";
}
.fo-avocado:before {
  content: "\5e";
}
.fo-coconut:before {
  content: "\5f";
}
.fo-lemon:before {
  content: "\60";
}
.fo-mango:before {
  content: "\95";
}
.fo-orange:before {
  content: "\96";
}
.fo-pineapple:before {
  content: "\97";
}
.fo-strawberry:before {
  content: "\98";
}
.fo-tuna:before {
  content: "\99";
}
.fo-bbq:before {
  content: "\9a";
}
.fo-chipotle:before {
  content: "\9b";
}
.fo-teriyaki:before {
  content: "\9c";
}
.fo-cheddar:before {
  content: "\9d";
}
.fo-parmesan:before {
  content: "\9e";
}
.fo-philadelphia:before {
  content: "\30";
}
.fo-butter:before {
  content: "\31";
}
.fo-olive:before {
  content: "\35";
}
.fo-salt:before {
  content: "\36";
}
.fo-soya:before {
  content: "\37";
}
.fo-mayonnaise:before {
  content: "\39";
}
.fo-egg:before {
  content: "\9f";
}
.fo-mustard:before {
  content: "\a0";
}
.fo-cereal-rice:before {
  content: "\32";
}
.fo-papaya:before {
  content: "\a1";
}
.fo-ketchup2:before {
  content: "\33";
}
